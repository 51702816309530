import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Error } from '@/common/components';
const defaultRoute = (routes, routerMap, pathname) => {
    const [match] = routes;
    const originPath = pathname.replace(/\/v2/g, '');
    let matchResult = null;
    let routerPathArr = [];
    Object.keys(routerMap).forEach((key) => {
        matchResult = key.match(/(\/[a-zA-Z]+)?(\/[a-zA-Z]+)/);
        routerPathArr = routerPathArr.concat(...[matchResult[0] || '', matchResult[1] || '']);
    });
    routerPathArr = Array.from(new Set([...routerPathArr, '/signin', '/signup', '/recovery', '/passwordsetup', '/invalidaccountactivation']));
    if (!originPath || routerPathArr.includes(originPath)) {
        return match;
    }
    return {};
};
const getHref = (href) => `/v2${href}`;
const createDeclareRoutes = (matchCallbak) => function declareRoutes({ routes, loaders, presets }, routerMap, renderPageFooter) {
    return (React.createElement(Switch, null,
        routes
            .filter(({ component, children }) => component || children)
            .map(({ key, href, exact, component, children }) => [
            component && (React.createElement(Route, { key: key, path: getHref(href), exact: typeof exact !== 'boolean' || exact, render: (props) => {
                    const WrapError = Error(loaders[component] || { config: { status: 404 } });
                    const { match } = props;
                    setTimeout(function () {
                        matchCallbak && matchCallbak(match);
                    }, 0);
                    return (React.createElement(WrapError, Object.assign({}, Object.assign({ renderPageFooter,
                        routerMap }, (presets || {})), props)));
                } })),
            children && children.filter((item) => item.href || item.component).length && (React.createElement(Route, { key: key, path: getHref(href), render: () => declareRoutes({
                    routes: children,
                    loaders,
                    presets
                }, routerMap, renderPageFooter) }))
        ]),
        React.createElement(Route, { render: () => {
                const defaultRouteObj = defaultRoute(routes, routerMap, location.pathname);
                return defaultRouteObj.href ? (React.createElement(Redirect, { to: getHref(defaultRouteObj.href || '/') })) : (React.createElement(Route, { path: location.pathname, component: Error({
                        config: {
                            status: 404
                        }
                    }) }));
            } })));
};
export default class Router extends React.Component {
    constructor(props) {
        super(props);
        this.key = null;
        this.key = props.location.key;
    }
    shouldComponentUpdate(props) {
        const legacy = this.key;
        this.key = props.location.key;
        return this.key !== legacy;
    }
    render() {
        const { currentMatchCallback } = this.props;
        const declareRoutes = createDeclareRoutes(currentMatchCallback);
        return declareRoutes(this.props.config, this.props.routerMap, this.props.renderPageFooter);
    }
}
