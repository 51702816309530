var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import 'whatwg-fetch';
import * as intl from 'react-intl-universal';
import * as moment from 'moment';
import retry from './retry';
import message from "antd/lib/message";
const getTimestamp = () => moment().format('YYYYMMDDHHmm');
const coverParams = (param, url) => {
    const excludeTimestampHosts = [
        'https://multilingual-gateway-uat.finpoints.tech',
        'https://multilingual-gateway.finpoints.tech'
    ];
    const shouldExcludeTimestamp = excludeTimestampHosts.some(host => url.startsWith(host));
    const params = Object.entries(param || {})
        .map(([key, value]) => `${key}=${typeof (value) === 'object' ? JSON.stringify(value) : value}`)
        .reduce((prev, curr) => `${prev}&${curr}`, shouldExcludeTimestamp ? '' : `t=${getTimestamp()}`);
    return params.startsWith('&') ? params.slice(1) : params;
};
const coverParams2 = (param) => {
    Object.entries(param || {});
    let key = Object.keys(param)[0];
    let value = param[key].length > 0 ? param[key].join(',') : param[key];
    return `${key}=${value}`;
};
const getRequestParams = (method, url, param) => {
    const data = Object.entries(param || {})
        .filter(([, value]) => (method === 'get'
        ? typeof (value) === 'number' || !!value
        : true))
        .reduce((prev, [key, value]) => (Object.assign(Object.assign({}, prev), { [key]: value })), {});
    switch (method) {
        case 'getPath': {
            return {
                info: encodeURI(url),
                init: {
                    method: 'GET',
                    credentials: 'same-origin'
                }
            };
        }
        case 'get':
        case 'delete': {
            return {
                info: encodeURI(`${url}?${coverParams(data, url)}`),
                init: {
                    method: method === 'delete' ? 'DELETE' : 'GET',
                    credentials: 'same-origin'
                }
            };
        }
        case 'postparam':
        case 'post': {
            const isPost = method === 'post';
            return {
                info: encodeURI(url),
                init: {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': isPost ? 'application/json' : 'application/x-www-form-urlencoded'
                    },
                    credentials: 'same-origin',
                    body: isPost ? JSON.stringify(data) : coverParams(data, url)
                }
            };
        }
        case 'postquery': {
            return {
                info: encodeURI(`${url}?${coverParams2(data)}`),
                init: {
                    method: 'POST',
                    credentials: 'same-origin'
                }
            };
        }
        case 'put': {
            return {
                info: encodeURI(url),
                init: {
                    method: 'PUT',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    credentials: 'same-origin',
                    body: JSON.stringify(data)
                }
            };
        }
        case 'upload': {
            return {
                info: encodeURI(url),
                init: {
                    credentials: 'include',
                    method: 'POST',
                    headers: {
                        Accept: 'application/json'
                    },
                    body: Object.entries(data)
                        .reduce((formData, [key, value]) => {
                        formData.append(key, value);
                        return formData;
                    }, new FormData())
                }
            };
        }
    }
};
const statusHandler = (res) => {
    window._traceid = res.headers.get('x-trace-id');
    return (res.ok ? res.json() : { code: res.status });
};
const codeHandler = (res) => {
    const { ret_code } = res;
    if (ret_code === 703) {
        message.destroy();
        message.error(intl.get('Error.703').d('商户已在新设备登录，请重新登录'));
        setTimeout(() => {
            window.location.replace('/v2/signin');
        }, 1000);
    }
    return res;
};
export default (config) => (method) => (url, data, options = { ignoreError: false }) => __awaiter(void 0, void 0, void 0, function* () {
    const { info, init } = getRequestParams(method, url, data);
    const useProxy = /^\/[0-9a-z]+/.test(url);
    const addProxyHeader = (initObj) => {
        if (!initObj.headers) {
            initObj.headers = {};
        }
        initObj.headers['X-Use-Proxy'] = true;
        return initObj;
    };
    try {
        return yield retry(config.retry)(() => __awaiter(void 0, void 0, void 0, function* () {
            return yield fetch(info, useProxy ? addProxyHeader(init) : init)
                .then(statusHandler)
                .then(codeHandler);
        }), url);
    }
    catch (err) {
        if (!options.ignoreError && config.onError)
            config.onError(err, url);
        throw err;
    }
});
