import Text, { Password, Number, InputNumber } from './Text';
import TextArea from './TextArea';
import Select from './Select';
import Radio from './Radio';
import Upload from './Upload';
import Captcha from './Captcha';
import Phone from './Phone';
import Checkbox from './Checkbox';
import RtText from './RtText';
import TreeSelect from './TreeSelect';
import DatePicker from './DatePicker';
import TimePicker from './TimePicker';
import SelectDropDown from './SelectDropDown';
import Custom from './Custom';
import FormList from './Formlist';
import EncryptableTextArea from './EncryptableTextArea';
export default {
    text: Text,
    textarea: TextArea,
    encryptabletextarea: EncryptableTextArea,
    password: Password,
    number: Number,
    inputNumber: InputNumber,
    select: Select,
    radio: Radio,
    upload: Upload,
    captcha: Captcha,
    phone: Phone,
    checkbox: Checkbox,
    rttext: RtText,
    treeselect: TreeSelect,
    datePicker: DatePicker,
    timePicker: TimePicker,
    selectDropdown: SelectDropDown,
    custom: Custom,
    formList: FormList,
};
