var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import * as intl from 'react-intl-universal';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Layout from "antd/lib/layout";
import ConfigProvider from "antd/lib/config-provider";
import Menu from "antd/lib/menu";
import Badge from "antd/lib/badge";
import Button from "antd/lib/button";
import Popover from "antd/lib/popover";
import Spin from "antd/lib/spin";
import Empty from "antd/lib/empty";
import zhCN from 'antd/lib/locale-provider/zh_CN';
import zhTW from 'antd/lib/locale-provider/zh_TW';
import enUS from 'antd/lib/locale-provider/en_US';
import Selector from '@/common/I18n/Selector';
import { Loading } from '@/common/components';
import Router from './Router';
import { messages, setting, user } from '@/api';
import TimeUtil from '@/utils/timeUtil';
import bind from '@/utils/common/eventBindUtil';
import { setCookie } from '@/utils/common/cookieUtil';
import RouterMap from './RouterMap';
import Icon from '@/components/Icon';
import { BellOutlined } from '@ant-design/icons';
const { Header, Sider, Content } = Layout;
const { SubMenu, Item } = Menu;
const mapStateToProps = (state) => ({
    shopId: state.user.getIn(['status', 'shopid']),
    isSpecial: state.user.getIn(['status', 'is_special'])
});
let Launcher = class Launcher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            noticeCount: 0,
            breadcrumbList: [],
            mnenuList: [],
            mainMenuKey: '',
            subMenuKey: '',
            defaultOpenKeys: [],
            mainMenuList: [],
            subMenuList: [],
            herfMap: {},
            noticeList: [],
            popLoading: false
        };
        this.mounted = false;
        this.checkPathChange = () => {
            const { mainMenuList } = this.state;
            const pathnameArr = location.pathname.split('/').filter((key) => !!key);
            const activeMenuKey = pathnameArr.length >= 2 ? pathnameArr[1] : mainMenuList[0].key;
            this.menuChange('main', activeMenuKey.toLocaleUpperCase());
        };
        this.menuChange = (type, menuKey, clickTrigger) => {
            if (menuKey === this.state.mainMenuKey || menuKey === this.state.subMenuKey)
                return;
            if (type === 'main') {
                const { history } = this.props;
                const { mainMenuList, herfMap } = this.state;
                let activeMenuKey = menuKey;
                if (!herfMap[activeMenuKey]) {
                    activeMenuKey = mainMenuList[0].key;
                }
                this.setState({
                    mainMenuKey: activeMenuKey
                }, () => {
                    clickTrigger && history.push(herfMap[activeMenuKey]);
                    this.getSubMenuList(activeMenuKey);
                });
            }
            else {
                this.setState({
                    subMenuKey: menuKey
                });
            }
        };
        this.getHref = (data) => {
            let href = data.href;
            if (href.split('/').length <= 3 && data.children && data.children.length) {
                href = this.getHref(data.children[0]);
            }
            return href;
        };
        this.getPathName = () => {
            const pathName = location.pathname.toUpperCase();
            return pathName.split('/').filter((key) => !!key);
        };
        this.getCurrentMenuKeys = () => {
            const pathName = this.getPathName();
            const currentMenuKeys = pathName.reduce((activeKeys, key, index) => activeKeys.concat(index ? [[activeKeys[index - 1], key].join('_')] : [key]), []);
            return currentMenuKeys.length > 1 ? currentMenuKeys : ['V2_PAYMENT_CHANNEL_SETTING'];
        };
        this.getSubMenuList = (currentMainMenuKey) => {
            const { mainMenuList } = this.state;
            if (!mainMenuList.length || !currentMainMenuKey)
                return;
            let defaultOpenKeys = [];
            let subMenuList = [];
            mainMenuList.forEach((item) => {
                if (item.key === currentMainMenuKey) {
                    subMenuList = item.children || [];
                }
                defaultOpenKeys = defaultOpenKeys.concat(item.children.map((subitem) => subitem.key));
            });
            this.setState({
                subMenuList,
                defaultOpenKeys
            });
        };
        this.logout = () => {
            user.logout().finally(() => {
                window.location.replace('/v2/signin');
            });
        };
        this.checkLogin = () => {
            user.checkLogin().then((res) => {
                if (res.ret_code !== 0) {
                    this.logout();
                }
            }).catch(() => {
                this.logout();
            });
        };
        this.checkNoticeCount = () => {
            messages.getNewMesgCount().then((res) => {
                if (res.ret_code === 0) {
                    this.setState({
                        noticeCount: res.ret_msg
                    });
                }
            });
        };
        this.getSystemMessage = () => __awaiter(this, void 0, void 0, function* () {
            var _a;
            this.setState({
                noticeList: [],
                popLoading: true,
            });
            const { data } = yield messages.getSystem({ page: 1, page_size: 5, is_read: 0, state: 0 });
            let arr = [];
            if (((_a = data === null || data === void 0 ? void 0 : data.rows) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                arr = data.rows.map((item) => {
                    let content2;
                    if (item.content !== '') {
                        if (item.content.indexOf('{') !== -1 && item.content.indexOf('}') !== -1) {
                            let { success_count, failed_count } = JSON.parse(item.content);
                            if (item.type === 3) {
                                content2 = intl.get('Util.CustomerImportMsg', { success_count, failed_count }) ||
                                    `
                                客户数字货币转账处理完成。</br>
                                转账成功${success_count}条；</br>
                                转账失败${failed_count}条；</br>
                                如有失败文件请点击附加链接下载。
                                `;
                            }
                            else {
                                content2 = intl.get('Util.CustomerImportMsg', { success_count, failed_count }) ||
                                    `
                                客户导入处理完成。</br>
                                导入成功${success_count}条；</br>
                                导入失败${failed_count}条；</br>
                                如有失败文件请点击附加链接下载。
                                `;
                            }
                        }
                        else {
                            content2 = item.content;
                        }
                    }
                    else {
                        content2 = intl.get('Util.ImportErrorTips') || '客户导入处理失败。';
                    }
                    return Object.assign(Object.assign({}, item), { content2 });
                });
            }
            this.setState({
                noticeList: arr,
                popLoading: false,
            });
        });
        this.getTimeZoneSetting = () => __awaiter(this, void 0, void 0, function* () {
            yield setting.getTimezone().then((res) => {
                if (res.ret_code === 0) {
                    const data = res.ret_msg.data;
                    TimeUtil.set(data);
                }
            });
        });
        this.currentMatchCallback = (match) => {
            const removePrefixPath = match.path.replace(/\/v2/g, '');
            const pathInfoList = this.routerMap[removePrefixPath];
            this.mounted && this.setState({
                breadcrumbList: pathInfoList || []
            });
        };
        this.renderSubMenu = (list) => {
            const { mainMenuKey } = this.state;
            if (!mainMenuKey)
                return React.createElement(React.Fragment, null);
            const pathName = this.getPathName().join('_');
            return list.map((item) => React.createElement(SubMenu, { key: item.key, icon: React.createElement(Icon, { icon: item.icon, style: { marginRight: 6 } }), title: intl.get(`Menu.${item.name}`) || item.title }, this.rederSubMenuItem(item.children, pathName)));
        };
        this.rederSubMenuItem = (list, currentMenuKey) => (list.map((subItem) => {
            const subMenuKey = `V2_${subItem.key}`;
            const subMenuName = intl.get(`Menu.${subItem.name}`) || subItem.title;
            if (subMenuKey !== 'V2_PAYOUT_MANAGE_BATCH_UPLOAD') {
                return React.createElement(Item, { key: subMenuKey }, subMenuKey !== currentMenuKey ? React.createElement(Link, { to: `/v2${subItem.href}` }, subMenuName) : subMenuName);
            }
        }));
        const { routesConfig } = this.props;
        this.routerMap = RouterMap(routesConfig.routes);
    }
    componentDidMount() {
        this.mounted = true;
        this.getTimeZoneSetting();
        this.checkNoticeCount();
        this.timer = setInterval(() => {
            this.checkLogin();
            this.checkNoticeCount();
        }, 60000);
        bind(window, 'load', function () {
            setCookie('fastlogin', '', -10);
        });
        bind(window, 'unload', function () {
            window.sessionStorage.setItem('sessionFlag', '1');
        });
    }
    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }
    UNSAFE_componentWillMount() {
        const { routesConfig } = this.props;
        const { routes = [] } = routesConfig;
        const mainMenuList = routes;
        this.setState({
            mainMenuList,
            herfMap: mainMenuList.reduce((prev, item) => (Object.assign(Object.assign({}, prev), { [item.key]: `/v2${this.getHref(item)}` })), {})
        }, () => {
            this.checkPathChange();
        });
    }
    UNSAFE_componentWillReceiveProps() {
        this.checkPathChange();
    }
    render() {
        const { history, location, lang, routesConfig, name = '' } = this.props;
        const { mainMenuKey, mainMenuList, noticeCount, subMenuList, defaultOpenKeys, noticeList, popLoading } = this.state;
        const msgPopover = () => {
            const onOpenChange = (open) => {
                if (open) {
                    this.getSystemMessage();
                }
            };
            function showMessageType(type) {
                let text;
                switch (type) {
                    case 1:
                        text = intl.get('Util.SystemMsgMenu') || '系统消息';
                        break;
                    case 2:
                        text = intl.get('Util.CustomerImpResNotifyTitle') || '客户导入结果通知';
                        break;
                    case 3:
                        text = intl.get('Util.CustomerImpResNotifyTitle') || '数字货币转账结果通知';
                    default:
                        text = intl.get('Util.UnknownMsg') || '未知消息';
                        break;
                }
                return text;
            }
            const content = () => (React.createElement("div", null,
                React.createElement(Spin, { spinning: popLoading },
                    React.createElement("div", { style: { maxHeight: '300px', minHeight: '100px', width: '300px', overflow: 'auto' } }, noticeList.length > 0 ?
                        noticeList.map((item, index) => {
                            var _a, _b;
                            return (React.createElement("div", { style: { minHeight: '70px', margin: "10px 0" }, key: item.id },
                                React.createElement("div", { style: { fontSize: '14px', fontWeight: 'bold' } }, showMessageType(item.type)),
                                React.createElement("div", { style: { color: 'darkgray', fontSize: '12px', margin: "5 0" } }, item.type === 1 ? React.createElement(React.Fragment, null, item.content2) : React.createElement("span", { dangerouslySetInnerHTML: { __html: item.content2 } })),
                                React.createElement("div", { style: { color: '#0676EA', fontSize: '12px', whiteSpace: 'pre-line' } },
                                    React.createElement("a", { href: (_a = item === null || item === void 0 ? void 0 : item.message_detail_list[0]) === null || _a === void 0 ? void 0 : _a.access_url }, (_b = item === null || item === void 0 ? void 0 : item.message_detail_list[0]) === null || _b === void 0 ? void 0 : _b.access_url))));
                        }) :
                        React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, description: intl.get('Util.NoMsgTips') || '没有最新的消息' })),
                    React.createElement("div", { style: { color: '#0676EA', fontSize: '14px', cursor: 'pointer', display: 'flex', justifyContent: 'center', borderTop: '.1px solid darkgray', height: '30px', lineHeight: '40px' }, onClick: () => history.push('/v2/message/manage/system') }, intl.get('Util.AllMessagesButton') || '查看所有通知'))));
            return (React.createElement(Popover, { content: content(), trigger: ['hover'], placement: "bottom", arrowPointAtCenter: true, onOpenChange: onOpenChange },
                React.createElement(BellOutlined, { style: { fontSize: '20px', marginRight: '20px' } })));
        };
        return (React.createElement(Layout, { className: `main-container ${name} lang-${lang}` },
            React.createElement(Header, { className: 'head-container' },
                React.createElement("div", { className: 'left-part' },
                    React.createElement("img", { className: 'logo', src: '/public/images/intepay-logo.png', alt: 'intepay' }),
                    React.createElement(Menu, { className: 'main-menu', mode: 'horizontal', selectedKeys: [mainMenuKey], onClick: (e) => this.menuChange('main', e.key, true) }, mainMenuList.map((menu) => {
                        const menuName = intl.get(`Menu.${menu.name}`) || menu.title;
                        return React.createElement(Menu.Item, { key: menu.key }, menu.key !== 'MESSAGE' ? menuName : React.createElement(Badge, { dot: true, count: noticeCount, offset: [2, 0] }, menuName));
                    }))),
                React.createElement("div", { className: 'right-part' },
                    msgPopover(),
                    React.createElement(Selector, null),
                    React.createElement(Button, { type: 'text', style: { marginLeft: 16 }, onClick: this.logout }, intl.get('Global.LogOut')))),
            React.createElement(Layout, null,
                React.createElement(Sider, { className: 'side-container', theme: 'light' }, subMenuList.length ? React.createElement(Menu, { className: 'sub-menu', mode: 'inline', expandIcon: React.createElement(React.Fragment, null), selectedKeys: this.getCurrentMenuKeys(), defaultOpenKeys: defaultOpenKeys, onClick: (e) => this.menuChange('sub', e.key) }, this.renderSubMenu(subMenuList)) : React.createElement(Loading, null)),
                React.createElement(Content, { className: 'content-container' },
                    React.createElement(Layout, null,
                        React.createElement(Content, { className: 'main-content' },
                            React.createElement(ConfigProvider, { locale: {
                                    'zh-CN': zhCN,
                                    'zh-TW': zhTW,
                                    'en-US': enUS
                                }[lang] },
                                React.createElement(Router, { history: history, location: location, config: routesConfig, routerMap: this.routerMap, currentMatchCallback: this.currentMatchCallback }))))))));
    }
};
Launcher = __decorate([
    withRouter,
    connect(mapStateToProps, null)
], Launcher);
export default Launcher;
