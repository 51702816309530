export default [
    {
        key: 'SIGN_IN',
        title: '登录',
        href: '/signin',
        exact: true,
        component: 'SignIn'
    },
    {
        key: 'RECOVERY',
        title: '重置密码',
        href: '/recovery',
        exact: true,
        component: 'Recovery'
    },
    {
        key: 'SIGN_UP',
        title: '注册',
        href: '/signup',
        exact: true,
        component: 'SignUp'
    },
    {
        key: 'PASSWORD_SETUP',
        title: '设置密码',
        href: '/passwordsetup',
        exact: true,
        component: 'PasswordSetup'
    },
    {
        key: 'INVALID_ACCOUNT_ACTIVATION',
        title: '账号激活失效',
        href: '/invalidaccountactivation',
        exact: true,
        component: 'InvalidAccountActivation'
    },
];
