import { get, post, upload } from '@/utils/http';
const getPayoutChannel = (data) => get('/setting/getPayoutChannel', data);
const createPayoutChannel = (data) => post('/setting/updatePayoutChannel', data);
const editPayoutChannel = (data) => post('/setting/updatePayoutChannel', data);
const getSinglePayoutChannel = (id) => get('/setting/payoutChannel/' + id);
const getAllPayoutChannel = () => get('/setting/getAllPayoutChannels/');
const createPayoutOrder = (data) => post('/payout/order/create', data);
const getPayoutOrder = (data) => get('/payout/order/list', data);
const getPayoutOrderExport = (data) => post('/payout/order/export', data);
const getSinglePayoutOrder = (id) => get('/payout/order/' + id);
const manualAuditPayoutOrder = (data) => post('/payout/order/manualAudit', data);
const getSinglePayoutOrderLog = (orderNo) => get('/payout/order/log/' + orderNo);
const createRegularReceiverAccount = (data) => post('/payout/regular/receiverAccount/update', data);
const updateRegularReceiverAccount = (data) => post('/payout/regular/receiverAccount/update', data);
const getRegularReceiverAccount = (data) => get('/payout/regular/receiverAccount/list', data);
const getSingleRegularReceiverAccount = (id) => get('/payout/regular/receiverAccount/' + id);
const deleteRegularReceiverAccount = (id) => post('/payout/regular/receiverAccount/delete/' + id);
const getPayoutOrderBalance = (data) => post('/payout/invoke/epayAdapterJPY/getBalance', data);
const getWowspayBalance = (data) => post('/payout/invoke/WowsPayAdapter/getBalance', data);
const getPoHelp2PayAdapterBalance = (data) => post('/payout/invoke/PoHelp2PayAdapter/getBalance', data);
const getGateExpressBalance = (data) => post('/payout/invoke/gateExpressAdapter/getBalance', data);
const getPOChippayAdapterBalance = (data) => post('/payout/invoke/POChippayAdapter/getBalance', data);
const getPOMyPayAdapterBalance = (data) => post('/payout/invoke/POMyPayAdapter/getBalance', data);
const getPayoutOrderLimit = (data) => post('/payout/invoke/epayAdapterJPY/getLimit', data);
const getPagsmileBalance = (data) => post('/payout/invoke/POPagsmileAdapter/getBalance', data);
const getPayoutBankList = (data) => post('/payout/invoke/epayAdapterJPY/getBankList', data);
const getPayoutCalculateAmount = (data) => post('/payout/invoke/epayAdapterJPY/calculateAmount', data);
const getPayoutPurposeOfRemittanceList = (data) => post('/payout/invoke/epayAdapterJPY/getPurposeOfRemittanceList', data);
const getPayoutSourceOfFundList = (data) => post('/payout/invoke/epayAdapterJPY/getSourceOfFund', data);
const getAllPayoutOrderChannel = () => get("/setting/payout/channel/list");
const payoutChannelChecking = (channelCode) => post(`/payout/check/invoke/${channelCode}`);
const getPayoutBankListForWowsPay = (data) => post('/payout/invoke/WowsPayAdapter/getBankList', data);
const getPayoutBankListFor12Pay = (data) => post('/payout/invoke/PO12PAYAdapter/getBankList', data);
const getPayoutBankListForHelp2Pay = (data) => post('/payout/invoke/PoHelp2PayAdapter/getBankList', data);
const getPayoutBankListForPagsmile = (data) => post('/payout/invoke/POPagsmileAdapter/getBankList', data);
const getPayoutAccountTypeForPagsmile = (data) => post('/payout/invoke/POPagsmileAdapter/getAccountTypeList', data);
const getPayoutDocumentTypeForPagsmile = (data) => post('/payout/invoke/POPagsmileAdapter/getDocumentTypeList', data);
const getPayoutRegionForPagsmile = (data) => post('/payout/invoke/POPagsmileAdapter/getRegionList', data);
const getPayoutBankListForPaymentAsia = (data) => post('/payout/invoke/POPaymentAsiaAdapter/getBankList', data);
const uploadBatchPayoutExcel = (data) => upload('/payout/channel/uploadBatchPayoutExcel', data);
export default {
    getPayoutOrderExport,
    getPayoutOrder,
    getAllPayoutChannel,
    getSinglePayoutChannel,
    getPayoutChannel,
    createPayoutChannel,
    editPayoutChannel,
    getRegularReceiverAccount,
    createRegularReceiverAccount,
    updateRegularReceiverAccount,
    deleteRegularReceiverAccount,
    createPayoutOrder,
    getSingleRegularReceiverAccount,
    getSinglePayoutOrderLog,
    getSinglePayoutOrder,
    manualAuditPayoutOrder,
    getPayoutOrderBalance,
    getPayoutOrderLimit,
    getPayoutBankList,
    getPayoutCalculateAmount,
    getPayoutPurposeOfRemittanceList,
    getPayoutSourceOfFundList,
    getAllPayoutOrderChannel,
    payoutChannelChecking,
    getWowspayBalance,
    getGateExpressBalance,
    getPagsmileBalance,
    getPayoutBankListForWowsPay,
    getPayoutBankListFor12Pay,
    getPayoutBankListForHelp2Pay,
    getPoHelp2PayAdapterBalance,
    getPayoutBankListForPagsmile,
    getPayoutAccountTypeForPagsmile,
    getPayoutDocumentTypeForPagsmile,
    getPayoutRegionForPagsmile,
    getPayoutBankListForPaymentAsia,
    uploadBatchPayoutExcel,
    getPOChippayAdapterBalance,
    getPOMyPayAdapterBalance
};
