var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import Input from "antd/lib/input";
import Tooltip from "antd/lib/tooltip";
import Button from "antd/lib/button";
import Modal from "antd/lib/modal";
import message from "antd/lib/message";
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import StaticView from './StaticView';
import { user } from '@/api';
import { AuthenticationContext } from '@/store/context/authenticationContext';
import * as intl from 'react-intl-universal';
export default class EncryptableTextArea extends React.Component {
    constructor(props) {
        super(props);
        this.handleTimerExpire = () => {
            this.context.setVerified(false);
            this.setState({
                visible: false,
                displayValue: this.props.encryptedValue
            });
        };
        this.toggleVisibility = () => {
            const currentTime = Date.now();
            const lastAuthTime = this.context.lastAuthTime;
            const isVerified = this.context.isVerified;
            const threeMinutes = 180000;
            if (!this.state.visible) {
                if ((!lastAuthTime || currentTime - lastAuthTime > threeMinutes) && !isVerified) {
                    this.setState({ showModal: true });
                }
                else {
                    this.setState(prevState => ({
                        visible: !prevState.visible,
                        displayValue: !prevState.visible ? this.state.value : this.state.encryptedValue
                    }), this.startTimer);
                }
            }
            else {
                this.setState(prevState => ({
                    visible: !prevState.visible,
                    displayValue: !prevState.visible ? this.state.value : this.state.encryptedValue
                }));
            }
        };
        this.startTimer = () => {
            if (!this.context.lastAuthTime) {
                this.context.startTimer(() => { }, 180000);
            }
        };
        this.handleOk = () => __awaiter(this, void 0, void 0, function* () {
            const { authCode } = this.state;
            if (!authCode) {
                message.error(intl.get('Util.MFAValidationEmptyError'));
                return;
            }
            try {
                const response = yield user.verifyAuthCode({ authenticateCode: authCode });
                if (response.ret_code === 0) {
                    const currentTime = Date.now();
                    this.setState(prevState => ({
                        visible: true,
                        displayValue: this.state.value,
                        showModal: false,
                        authCode: ''
                    }), this.startTimer);
                    this.context.setVerified(true);
                    this.context.setLastAuthTime(currentTime);
                    this.context.setShowModal(false);
                }
                else {
                    message.error(intl.get('Util.InvalidationMFAMessage'));
                }
            }
            catch (error) {
                message.error(intl.get('Util.MFAValidationFailedMessage'));
            }
        });
        this.handleCancel = () => {
            this.setState({ showModal: false, authCode: '' });
        };
        this.handleAuthCodeChange = (e) => {
            const value = e.target.value;
            if (/^\d*$/.test(value) && value.length <= 6) {
                this.setState({ authCode: value });
            }
        };
        this.handleEncryptChange = (e) => {
            this.setState({ displayValue: '' });
            const newValue = e.target.value;
            if (this.props.onChange) {
                if (this.context.isVerified) {
                    this.setState({ displayValue: newValue, value: newValue, visible: true });
                    this.props.onChange(e);
                }
                else {
                    this.setState({ displayValue: newValue });
                    this.props.onChange(e);
                }
            }
        };
        this.handleNormalChange = (e) => {
            const newValue = e.target.value;
            this.setState({ displayValue: newValue, value: newValue });
            if (this.props.onChange) {
                this.props.onChange(e);
            }
        };
        this.handleFocus = () => {
            if (!this.state.visible) {
                this.setState({
                    displayValue: ''
                });
            }
        };
        this.handleBlur = (e) => {
            if (!this.state.visible) {
                if (this.state.value == e.target.value || !this.context.isVerified) {
                    this.setState({
                        displayValue: this.state.encryptedValue
                    });
                }
                else {
                    this.setState({
                        displayValue: this.state.encryptedValue,
                        value: e.target.value
                    });
                }
            }
            else {
                this.setState({
                    displayValue: this.state.value,
                });
            }
        };
        this.state = {
            value: this.props.value,
            encryptedValue: this.props.encryptedValue,
            visible: false,
            displayValue: props.encryptedValue || '',
            showModal: false,
            authCode: '',
            isRegistered: this.props.isRegistered,
            imageQrUri: this.props.imageQrUri,
            isValid: this.props.isValid
        };
    }
    componentDidMount() {
        this.context.subscribe(this.handleTimerExpire);
    }
    componentWillUnmount() {
        this.context.unsubscribe(this.handleTimerExpire);
        if (this.context.timer) {
            clearTimeout(this.context.timer);
        }
    }
    render() {
        const _a = this.props, { disabled, staticView, initialValue, copy, readOnly, link, href, target, wrapBool, tooltip, isValid } = _a, props = __rest(_a, ["disabled", "staticView", "initialValue", "copy", "readOnly", "link", "href", "target", "wrapBool", "tooltip", "isValid"]);
        const { visible, displayValue, showModal, isRegistered, imageQrUri, value } = this.state;
        const textAreaInput = (React.createElement(Input.TextArea, Object.assign({ value: displayValue || initialValue || '', autoSize: { minRows: 1, maxRows: 3 }, onChange: this.handleEncryptChange, onFocus: this.handleFocus, onBlur: this.handleBlur }, props.style)));
        const textInput = (React.createElement(Input, Object.assign({ value: value, onChange: this.handleNormalChange }, props.style)));
        const eyeIcon = visible ? React.createElement(EyeOutlined, null) : React.createElement(EyeInvisibleOutlined, null);
        const ctx = (staticView && disabled || readOnly)
            ? React.createElement(StaticView, Object.assign({ display: displayValue || initialValue || '-', copy: copy, link: link, href: href, wrap: wrapBool, target: target }, props))
            : (React.createElement("div", Object.assign({ style: { position: 'relative', display: 'flex' } }, props.style),
                isValid ? textAreaInput : textInput,
                isValid && (React.createElement(Button, Object.assign({ icon: eyeIcon, onClick: this.toggleVisibility, style: { position: 'absolute', right: 0, top: 0, height: '100%' } }, props.style)))));
        return (React.createElement(React.Fragment, null,
            tooltip ? React.createElement(Tooltip, { placement: 'topLeft', title: tooltip }, ctx) : ctx,
            React.createElement(Modal, { title: React.createElement("div", { style: { fontSize: '20px' } }, intl.get('Util.MFAValidationTitle') || "校验2FA"), visible: showModal, onOk: this.handleOk, onCancel: this.handleCancel, maskClosable: false, footer: false },
                React.createElement("div", null,
                    !isRegistered &&
                        React.createElement(React.Fragment, null,
                            React.createElement("span", { style: { color: 'blue', fontSize: '12px', marginTop: '-15px' }, dangerouslySetInnerHTML: { __html: intl.get('Util.RegisterMFAScanQRMessage') } }),
                            React.createElement("img", { style: { display: 'block', margin: 'auto', width: '50%' }, src: imageQrUri })),
                    React.createElement("div", { style: { maxWidth: '300px', margin: 'auto', display: 'flex', gap: '10px', alignItems: 'center' } },
                        React.createElement("span", { style: { whiteSpace: 'nowrap' } },
                            intl.get('Util.LoginWithMFATitleLabel') || 'Enter 2FA Code',
                            ":"),
                        React.createElement(Input.Password, { placeholder: intl.get('Util.Enter2FAPlaceholder'), value: this.state.authCode, onChange: this.handleAuthCodeChange, iconRender: visible => (visible ? React.createElement(EyeOutlined, null) : React.createElement(EyeInvisibleOutlined, null)), name: 'authenticateCode' }))),
                React.createElement(Button, { type: "primary", onClick: this.handleOk, style: { display: 'flex', margin: '2rem auto' } }, intl.get('Global.MfaVerifyCode')))));
    }
}
EncryptableTextArea.fieldName = 'encryptabletextarea';
EncryptableTextArea.type = 'EncryptableTextArea';
EncryptableTextArea.contextType = AuthenticationContext;
